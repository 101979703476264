<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input
            class="w120 mb10 mr10"
            placeholder="分类名称"
            v-model="filterName"
        />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
<!--      <div style="margin-left: auto">-->
<!--        &lt;!&ndash; <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> &ndash;&gt;-->
<!--        <el-button class="ma" type="primary" @click="centerDialogVisible = true"-->
<!--        >+ 添加商品</el-button-->
<!--        >-->
<!--      </div>-->
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="name" align="center" label="用户昵称" />
        <el-table-column prop="name" align="center" label="商品名称" />
        <el-table-column prop="name" align="center" label="商品主图" >
          <template slot-scope="scope">
            <img :src="scope.row.thumbnail" alt="" style="width: 80px;height: 40px" />
          </template>
        </el-table-column>
        <el-table-column prop="targetMoney" align="center" label="商品类型" />
        <el-table-column prop="completeMoney" align="center" label="兑换时间时间" />
        <el-table-column prop="createTime" align="center" label="消耗积分" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
            >编辑</el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.donationId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="添加商品"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item label="商品类型" prop="goodType">
          <el-select
              v-model="ruleForm.type"
              style="width: 100%"
          >
            <el-option
                v-for="(item,index) in goodTypeList"
                :key="index"
                :label="item"
                :value="index"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="projectTitle" label="商品名称">
          <el-input
              v-model="ruleForm.projectTitle"
              style="width: 100%"
              placeholder="请输入商品名称"
              maxlength="30"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="coverImg"  label="缩略图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            上传图片及支持png/jpg 。显示图片长宽比为5:4
          </p>
          <div>
            <el-upload
                :action="uploadUrl"
                :class="{
              disabled: uploadDisabled2,
            }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove2"
                :on-success="shareUpload"
                :file-list="ruleForm.coverImg"
                :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </el-form-item>

        <el-form-item prop="inventory" label="库存">
          <el-input
              v-model="ruleForm.inventory"
              style="width: 100%"
              placeholder="请输入库存"
          ></el-input>
        </el-form-item>
        <el-form-item prop="money" label="现金金额">
          <el-input
              v-model="ruleForm.money"
              style="width: 100%"
              placeholder="请输入现金金额"
          ></el-input>
        </el-form-item>
        <el-form-item prop="integral" label="所需积分">
          <el-input
              v-model="ruleForm.integral"
              style="width: 100%"
              placeholder="请输入所需积分"
          ></el-input>
        </el-form-item>

        <el-form-item prop="goodPhoto" label="详情图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，商品图片一共可以上传5张，默认第一张为主图封面
          </p>
          <div style="display: flex">
            <el-upload
                :action="uploadUrl"
                :class="{
              disabled: uploadDisabled,
            }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="goodsUpload"
                :file-list="ruleForm.goodPhoto"
                :limit="15"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="商品详情" prop="projectDetails">
          <!-- //富文本 -->
          <quill-editor
              style="height: 100%"
              v-model="ruleForm.projectDetails"
              ref="myQuillEditor"
              :options="editorOption"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写内容'

import {addDonation,selectDonationList,upDonation,delDonation} from "@/api/content";
export default {
  name: "integraluseRecord",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      goodTypeList:['类型1','类型2'],
      editorOption: quillConfig,
      centerDialogVisible: false,
      ruleForm: {
        type:"",
        projectTitle: "",
        projectDetails:"",
        coverImg: [],
        goodPhoto:[],
        inventory:"",
        integral:"",
        money:"",
      },
      dialogVisible: false,
      rules: {
        projectTitle: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        projectDetails: [{ required: true, message: "请输入项目内容", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传图片"}],
        goodPhoto: [{ required: true, message: "请上传图片"}],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{ label: "全部", value: 1 }],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate:1,
      donationId:''
    };
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.goodPhoto.length >= 5;
    },
    uploadDisabled2() {
      return this.ruleForm.coverImg && this.ruleForm.coverImg.length >= 1;
    },
  },
  mounted(){
    // this.queryPage();
  },
  methods: {
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await selectDonationList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
          if (list[i].status==0){
            list[i].status = '未开始'
          }else if (list[i].status==1){
            list[i].status = '进行中'
          }else if (list[i].status==2){
            list[i].status = '已完成'
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    //搜索
    async filterNames() {
      this.tableData = this.tableData.filter(
          (item) => this.filterName == item.name
      );
      if (this.filterName.length < 1) {
        this.queryPage();
      }
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    async saveSort(row){
      if(row && row.sort != ""){
        const result = await updateGoodsTypeSort(row);
        this.$message({
          type: "info",
          message: "保存成功",
        });
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    determine(){
      if(this.ruleForm.goodPhoto.length == 0){
        return this.$message({
          message: "商品图片未上传",
          type: "warning",
          center: true,
        });
      }
      if(this.ruleForm.coverImg.length == 0){
        return this.$message({
          message: "商品分享图片未上传",
          type: "warning",
          center: true,
        });
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let arr = this.ruleForm.goodPhoto.map((item) => item.url);
            let data = {
              associationId: localStorage.getItem("associationId"),
              name:this.ruleForm.projectTitle,
              targetMoney:this.ruleForm.money,
              content:this.ruleForm.projectDetails,
              thumbnail:this.ruleForm.coverImg && this.ruleForm.coverImg.length != 0
                  ? this.ruleForm.coverImg[0].url
                  : [],
              chart:arr.join(",")
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addDonation(data);
            }else {
              data.donationDailyId = this.donationDailyId
              await upDonation(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delDonation({ donationId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },

    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val));
      if (data.chart) {
        this.ruleForm.goodPhoto = data.chart.split(",").map((item, index) => {
          return {
            url: item,
            uid: index,
          };
        });
      }
      if (data.thumbnail && data.thumbnail.length !== 2 && data.thumbnail.length !== 0) {
        this.ruleForm.coverImg = [{ url: data.thumbnail, uid: 1 }];
      } else {
        this.ruleForm.coverImg = [];
      }
      this.ruleForm.projectTitle = data.name;
      this.ruleForm.money = data.targetMoney;
      this.ruleForm.projectDetails = data.content;
      this.donationId = data.donationId
    },

    //分享图片
    shareUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm.coverImg = arr;
    },
    handleRemove(file, fileList) {
      this.ruleForm.goodPhoto = fileList;
    },
    handleRemove2(file, fileList) {
      this.ruleForm.coverImg = fileList;
    },
    // //商品图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });

      this.ruleForm.goodPhoto = arr;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        projectTitle: "",
        money: "",
        projectDetails:"",
        coverImg: [],
        goodPhoto:[]
      }),
          this.centerDialogVisible = false;
    },
    saveStatus(){

    }
  },
};
</script>

<style lang="scss" scoped></style>


